import axios from "axios";
import { decode, JwtPayload } from "jsonwebtoken";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { hotjar } from "react-hotjar";

import { routeStart, routePurchaseStart } from "lib/flow";
import CanastaStorage from "lib/storage";

export default function Home() {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) routePurchaseStart(router);
    hotjar.initialize(
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      process.env.NEXT_PUBLIC_HOTJAR_HJID,
      process.env.NEXT_PUBLIC_HOTJAR_HJSV,
    );
  }, [router.isReady]);

  return (
    <div>
      <Head>
        <title>Canasta Ahorro</title>
      </Head>
      <main></main>
    </div>
  );
}
