import { ProductsToPick } from "domain/entities/Product";

const VALID_FIELDS = [
  "_id",
  "cost",
  "completed",
  "id",
  "orderProgress",
  "payment",
  "payment_method",
  "pickingProgress",
  "products",
  "seller",
  "shipping_data",
  "shipping_index",
  "status",
  "user",
];

const CanastaStorage = {
  get: () => {
    const basketObject = JSON.parse(localStorage.getItem("canasta") || "{}");
    const basketKeys = Object.keys(basketObject);
    basketKeys.forEach((key) => {
      if (!VALID_FIELDS.includes(key)) delete basketObject[key];
    });
    return basketObject;
  },
  getAccessToken: () => localStorage.getItem("access-token"),
  getPicking: () => {
    return JSON.parse(localStorage.getItem("products-to-pick") || "{}");
  },
  getRefreshToken: () => localStorage.getItem("refresh-token"),
  getUserData: () => {
    return JSON.parse(localStorage.getItem("user-data") || "{}");
  },
  setAccessToken: (token: string) =>
    localStorage.setItem("access-token", token),
  setRefreshToken: (token: string) =>
    localStorage.setItem("refresh-token", token),
  setPicking: (products: ProductsToPick[] | undefined) => {
    if(products) {
      localStorage.setItem("products-to-pick", JSON.stringify(products));
    }
  },
  setUserData: (data: any) =>
    localStorage.setItem("user-data", JSON.stringify(data)),
  clear: () => {
    localStorage.removeItem("canasta");
  },
  update: (data: any) => {
    const dataKeys = Object.keys(data);
    const filteredData = { ...data };
    dataKeys.forEach((key) => {
      if (!VALID_FIELDS.includes(key)) delete filteredData[key];
    });
    const existing = JSON.parse(localStorage.getItem("canasta") || "{}");
    if (existing === null) {
      localStorage.setItem("canasta", JSON.stringify(filteredData));
    } else {
      localStorage.setItem(
        "canasta",
        JSON.stringify({ ...existing, ...filteredData }),
      );
    }
  },
};

export default CanastaStorage;
